<template>
  <div class="main" data-title="营销统计" v-title>
    <div class="table-wrapper">
      <table class="table">
        <tr>
          <th rowspan="2">销售顾问</th>
          <th :colspan="Object.keys(listData[0].timeInterval).length">销售顾问--在各时间段接待客户数</th>
          <th rowspan="2">旧车评估数</th>
          <th :colspan="Object.keys(listData[0].customerArea).length">销售顾问接待各客户--各区域客户来源数</th>
          <th :colspan="Object.keys(listData[0].demandCarType).length">销售顾问接待各客户--客户关注车型数</th>
          <th :colspan="Object.keys(listData[0].customerLevel).length">销售顾问--客户意向级别数</th>
          <th :colspan="Object.keys(listData[0].influence).length">销售顾问接待各客户--客户获知信息渠道来源数</th>
        </tr>
        <tr>
          <th :key="key" v-for="(items, key) in listData[0].timeInterval">{{getCfgData(key)}}</th>
          <th :key="key" v-for="(items, key) in listData[0].customerArea">{{getCfgData(key)}}</th>
          <th :key="key" v-for="(items, key) in listData[0].demandCarType">{{getCfgData(key)}}</th>
          <th :key="key" v-for="(items, key) in listData[0].customerLevel">{{getCfgData(key)}}</th>
          <th :key="key" v-for="(items, key) in listData[0].influence">{{getCfgData(key)}}</th>
        </tr>

        <tr :key="index" v-for="(item, index) in listData">
          <td>{{$store.state.userList[item.seller_uid][0]}}</td>
          <td :key="key" v-for="(items, key) in item.timeInterval">{{items}}</td>
          <td>{{item.usedEvaluate}}</td>
          <td :key="key" v-for="(items, key) in item.customerArea">{{items}}</td>
          <td :key="key" v-for="(items, key) in item.demandCarType">{{items}}</td>
          <td :key="key" v-for="(items, key) in item.customerLevel">{{items}}</td>
          <td :key="key" v-for="(items, key) in item.influence">{{items}}</td>
        </tr>
      </table>
    </div>

    <!-- <van-popup position="bottom" v-model="showSearch">
      <select-dep @setDeps="setDeps" ref="selectDep"></select-dep>
      <el-date-picker
        :clearable="false"
        @change="getNewData"
        end-placeholder="结束月份"
        format="yyyy年MM月"
        range-separator="-"
        start-placeholder="开始月份"
        style="margin-left:20px"
        type="monthrange"
        v-model="key"
        value-format="yyyy-MM"
      ></el-date-picker>
    </van-popup>-->
    <chart-search></chart-search>
  </div>
</template>
<script>
import { monthFormat } from '@/unit/unit.js'
import ChartSearch from '@/components/chart/ChartSearch.vue'
export default {
  components: {
    ChartSearch
  },
  data() {
    return {
      key: [monthFormat(), monthFormat()],
      dept_ids: [],
      listData: []
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    setDeps(arr) {
      this.dept_ids = arr
      this.getListData()
    },
    getNewData() {
      this.getListData()
    },
    getListData() {
      this.$axios
        .post('/statistic/data2', {
          begin_month: this.key[0],
          end_month: this.key[1],
          dept_ids: this.dept_ids
        })
        .then(res => {
          if (res.data.code == 200) {
            this.listData = res.data.data.list
          }
        })
    },
    getCfgData(val) {
      if (val == '') {
        return '-'
      }
      let i = 0

      if (typeof val == 'string') {
        i = parseInt(val)
      } else {
        i = val
      }

      return this.$store.state.cfgData[i][0]
    }
  }
}
</script>
<style lang="less" scoped>
.table-wrapper {
  width: 100%;
  overflow-x: auto;
  .table {
    border-collapse: collapse;
    th,
    td {
      background: #f5f7fa;
      color: rgba(0, 0, 0, 0.85);
      border: 1px solid #ebeef5;
      padding: 7px 10px;
      font-size: 12px;
      word-break: keep-all;
      white-space: nowrap;
      text-align: center;
      &:first-child {
        border-left: none;
      }
    }

    td {
      background: #fff;
      color: #606266;
    }
  }
}
</style>